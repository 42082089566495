<template>
  <header v-bind:class="mobileMenuOpen ? 'open' : ''">
    <div class="container d-md-flex">
      <div class="flex-grow-1 pt pb">
        <router-link to="/" class="d-block">
          <img :src="getAssetURL('img/ndalegal-logo.svg')" />
        </router-link>
      </div>
      <nav class="d-md-flex align-items-center justify-content-end">
        <router-link
          :to="item.href"
          class="text-white d-block ms-4 text-uppercase"
          v-for="item in links.standard"
          :key="item"
        >
          <span>
            {{ item.label }}
          </span>
        </router-link>
        <div class="d-flex">
          <router-link
            :to="item.href"
            class="text-white d-block ms-4 text-uppercase"
            v-for="item in links.social"
            :key="item"
          >
            <img v-if="item.image" :src="getAssetURL(item.image)" :alt="item.label">
          </router-link>
        </div>
      </nav>
    </div>
    <span @click="toggleMobileMenu">menu</span>
  </header>
</template>

<script>
export default {
  data () {
    return {
      mobileMenuOpen: false,
      links: {
        standard: [
          { label: 'Rólunk', href: '/placeholder' },
          { label: 'Szakterületeink', href: '/placeholder' },
          { label: '@NDA', href: '/placeholder' },
          { label: 'Hírek&Cikkek', href: '/placeholder' },
          { label: 'Kapcsolat', href: '/placeholder' }
        ],
        social: [
          { label: 'facebook', image: 'img/facebook-f.svg', href: '/placeholder' },
          { label: 'linkedin', image: 'img/linkedin-in.svg', href: '/placeholder' }
        ]
      }
    }
  },
  methods: {
    getAssetURL: function(data) {
      return require('../assets/' + data)
    },
    toggleMobileMenu: function() {
      this.mobileMenuOpen = !this.mobileMenuOpen
    }
  }
}
</script>

<style lang="postcss" scoped>
header {
  background: var(--c3);
  overflow: hidden;
}
header nav {
  background: var(--c5);
  position: relative;
}
header nav:after {
    content: '';
    background: var(--c5);
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
}
header > div.container > div {
  position: relative;
  z-index: 1;
}
header > div.container > div:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 125px 125px 0;
    border-color: transparent var(--c5) transparent transparent;
    position: absolute;
    top: 0;
    right: 0;
}
header > div img {
  height: 65px;
  width: auto;
}
header a {
  font-size: 15px;
  text-decoration: none;
}
header a:hover {
  text-decoration: underline;
}
header nav img {
  height: 1rem;
  margin-bottom: 6px;
}
header > span {
  display: none;
}
@media (max-width: 991px) {
  header a {
    margin-left: 10px !important;
    font-size: 13px;
  }
  header > div.container > div:after {
    border-width: 0 60px 125px 0;
  }
}
@media (max-width: 767px) {
  header {
    transition: .4s background-color;
  }
  header nav {
    transition: .4s all;
  }
  header.open {
    background-color: var(--c5);
  }
  header:not(.open) nav {
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }
  header nav:after {
    display: none;
  }
  header > div.container > div:after {
    right: 30px;
    border-width: 0 90px 125px 0;
    transition: .4s all;
  }
  header > div.container > div:before {
    content: '';
    background-color: var(--c5);
    position: absolute;
    top: 0;
    left: calc(100% - 30px);
    width: 100%;
    height: 100%;
    transition: .4s all;
  }
  header.open > div.container > div:after,
  header.open > div.container > div:before {
    transform: translateX(-50px);
    opacity: 0;
  }
  header nav a {
    font-size: 15px;
    line-height: 3em;
  }
  header nav {
    border-top: 1px solid #fff;
    padding-top: 30px;
  }
  header nav div {
    border-top: 1px solid #fff;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  header > span {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 33px;
    height: 33px;
    text-align: center;
    z-index: 3;
    font-size: 13px;
    transition: .2s all;
    color: #fff;
  }
  header.open > span {
    color: transparent;
    transform: rotate(-45deg);
  }
  header.open > span:before,
  header.open > span:after {
    transition: .2s transform;
  }
  header:not(.open) > span:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    top: calc(50% + 5px);
    left: 0;
  }
  header:not(.open) > span:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    top: calc(50% + 15px);
    left: 0;
  }
  header.open > span:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  header.open > span:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
  }
}
</style>
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', component: () => import('../views/Home.vue') },
  { path: '/placeholder', component: () => import('../views/Placeholder.vue') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
